<script lang="ts">
  import classNames from "classnames";
  import { createEventDispatcher } from "svelte";

  export let value: boolean = false;
  export let yesLabel: string = 'Si';
  export let noLabel: string = 'No';
  export let id: string = Math.random().toString(36).substring(7);
  export let hiddenValue: boolean = false;
  export let disabled = false;
  export let error: string | null | undefined = undefined;
  export let checked: boolean = false;

  let label: string = value ? yesLabel : noLabel;

  const dispatcher = createEventDispatcher();

  $: checked = value;

  function handleChange(event) {
    dispatcher("change", event);
    checked = event.target.checked;
    value = event.target.checked;
  }

  $: {
    label = value ? yesLabel : noLabel;
  }
</script>

<div class={classNames("flex items-center justify-center", $$props.class)}>
  <div>
    <label for={`switch-${id}`} class={classNames("switch-toggle cursor-pointer", $$props.class)} class:cursor-not-allowed={disabled}>
      <input type="checkbox" class="hidden" name={`switch-${id}`} id={`switch-${id}`} {value} {disabled} on:change={handleChange}>
      <span class="switch-label" class:hidden="{hiddenValue}">{label}</span>
      <button
        type="button"
        class="pointer-events-none switch-button"
        role="switch"
        aria-checked={checked}
        aria-labelledby={`switch-${id}`}
      >
        <span
          aria-hidden="true"
          class="absolute bg-white w-full h-full rounded-md"
        />
        <span aria-hidden="true" class:checked class="switch-background" />
        <span aria-hidden="true" class:checked class="switch-lever" />
      </button>
    </label>
  </div>
</div>

{#if error}
  <span class="error">{error}</span>
{/if}

<style type="text/scss" lang="scss" scoped>
  .switch-toggle {
    @apply flex;
  }

  .switch-button {
    @apply inline-flex flex-shrink-0 items-center justify-center;
    @apply relative;
    @apply h-5 w-10 rounded-full;
    @apply cursor-pointer;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand focus:ring-opacity-30;
  }

  .switch-label {
    @apply mr-4;
  }

  .switch-background {
    @apply absolute mx-auto;
    @apply h-4 w-9 rounded-full;
    @apply pointer-events-none;
    @apply transition-colors ease-in-out duration-200;

    &.checked {
      @apply bg-gray-900;
    }
    &:not(.checked) {
      @apply bg-gray-300;
    }
  }

  .switch-lever {
    @apply pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-300 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200;

    &.checked {
      @apply translate-x-5;
    }

    &:not(.checked) {
      @apply translate-x-0;
    }
  }

  .error {
    @apply text-xs m-0;
    @apply text-error;
  }
</style>
